import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

export class FirebaseContext {
  private static firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  private static firebaseApp: FirebaseApp;

  /**
   * Returns the Firebase app instance, initializing it if not already initialized.
   */
  public static getApp(): FirebaseApp {
    if (!this.firebaseApp) {
      this.validateConfig();
      this.firebaseApp = initializeApp(this.firebaseConfig);
    }
    return this.firebaseApp;
  }

  /**
   * Returns the Firebase Auth instance for the app.
   */
  public static getAuth(): Auth {
    if (!this.firebaseApp) this.getApp();
    return getAuth(this.firebaseApp);
  }

  /**
   * Validates that the Firebase config is properly set up.
   * Throws an error if any required config values are missing.
   */
  private static validateConfig(): void {
    const missingKeys = Object.entries(this.firebaseConfig).filter(([, value]) => !value);

    if (missingKeys.length > 0) {
      throw new Error(`Missing Firebase configuration keys: ${missingKeys.map(([key]) => key).join(', ')}`);
    }
  }
}
