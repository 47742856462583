import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  selectIsLoggedIn,
  setAccessToken,
  setLoggedIn,
  setTokenExpiryDate,
} from '../../app/redux/authorization.slice.reducer';
import { logger } from '../../config/Logger';
import { FirebaseContext } from '../../context/firebase.context';
import { AuthHelper } from '../../pkg/apiHelpers/authHelper';
import { GetDefaultRolesRequest, LoginRequest, RoleDto } from '../../pkg/protobuf/v2/auth/auth_types_pb';
import styles from './Login.module.scss';

export const Login = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const defaultState: Readonly<SignInScreenComponentState> = {
    isSignedIn: false,
  };
  const [state, setState] = React.useState(defaultState);
  const [role, setRole] = useState(new RoleDto());

  interface SignInScreenComponentState {
    isSignedIn: boolean;
  }

  useEffect(() => {
    if (isLoggedIn) history.push('/dashboard');
    FirebaseContext.getAuth();

    async function fetchStatus() {
      const requestMessage: GetDefaultRolesRequest = new GetDefaultRolesRequest();
      const resp = await AuthHelper.publicGetDefaultRolesPromise(requestMessage);
      resp?.roles.map((value: any) => {
        logger.debug({ message: value.name });
        if (value.name === 'defaultVisitorRoleAll') setRole(value);
      });
    }
    fetchStatus();
  }, []);

  const onSignInSubmit = () => {
    const auth = FirebaseContext.getAuth(); // Get Auth instance
    const provider = new GoogleAuthProvider(); // Google Auth Provider

    signInWithPopup(auth, provider)
      .then(async function (result) {
        // User signed in successfully.
        const user = result.user;
        const jwtFirebaseAuthToken = await user?.getIdToken(true);
        // send auth token to backend and get another reinforced JWT token with roles, names, access_level.
        // you will be able to use the new auth token for all the endpoints but you will be able to use the firebase token only for login
        if (jwtFirebaseAuthToken) {
          const loginRequest = new LoginRequest();
          loginRequest.jwtToken = jwtFirebaseAuthToken;
          loginRequest.roleId = role.id;
          const resp = await AuthHelper.loginRegisterFirebaseUser(loginRequest);
          if (!resp?.jwtToken) {
            // TODO: react an nextjs error?
            logger.error({ message: 'empty user token' });
            throw new Error('empty user token');
          }

          setState({ isSignedIn: true });

          // read jwt token
          const decoded: any = jwt_decode(resp?.jwtToken);
          const expiredIn = decoded.exp;

          // store token data in redux state
          dispatch(setLoggedIn(true));
          dispatch(setAccessToken(resp?.jwtToken));
          // dispatch(setAccessTokenAfterOTP(resp?.getJwttoken()));
          dispatch(setTokenExpiryDate(Number(expiredIn)));
          history.push('/dashboard');
        } else {
          logger.error({ message: 'token is empty cannot login or register' });
        }
      })
      .catch(function (error: any) {
        logger.error({ message: error });
      });
  };

  return (
    <div className={styles.loginWrapper}>
      <img className={styles.logo} src="/img/logo.svg" alt="logo" />
      <h3 className={styles.brandName}>OrangeQuest</h3>
      <div
        tabIndex={0}
        role="button"
        className={styles.buttonWrapper}
        onMouseDown={(e) => {
          if (e.nativeEvent.which === 1) {
            localStorage.setItem('isAuthenticated', String(true));
            // history.push('/dashboard');
            onSignInSubmit();
          }
        }}
      >
        <img src="/img/google.svg" alt="google" />
        <span>Sign in with google</span>
      </div>
    </div>
  );
});
